import { MaritalStatusEmployeeEnum } from '~/api/constants';

export const enUsRhPageTranslations = {
  rh: {
    employees: {
      view: {
        pageHeader: {
          currentStep: 'Collaborators',
          subtitle: 'Collaborators',
          button: 'Send employees via e-mail',
        },
        title: 'Collaborators',
        tabs: {
          all: 'All',
          active: 'Active',
          inactive: 'Inactive',
        },
        addCollaborator: 'Add collaborator',
        error: {
          document: 'Invalid document',
          notFound: 'Collaborator not found',
        },
        sendEmailDialog: {
          title: 'Send collaborator details by e-mail',
          content: 'This process may take some time, but do not worry. You will receive it soon.',
          confirm: 'Send',
          cancel: 'Cancel',
        },
        successDialog: {
          title: 'E-mail sent successfully',
        },
      },
      newCollaborators: {
        pageHeader: {
          previousStep: 'Balance recharge',
          currentStep: 'New recharge',
          subtitle: {
            send: 'Send',
            review: 'Review',
            validation: 'Validation',
            success: 'All set!',
          },
        },
        tabs: {
          send: {
            title: '1. Order submission',
            sheetModel: {
              title:
                'To register, you need to use the provided spreadsheet model. You can register from 1 person.',
              button: 'Download model spreadsheet',
            },
            divider: 'or, if you already have the spreadsheet ready, upload it',
            fileArea: {
              title: 'Upload your spreadsheet',
              formats: 'The file must be in csv format.',
              button: 'Select file',
              paragraph: 'or drag and drop here',
            },
            buttons: {
              cancel: 'Cancel',
              next: 'Next',
            },
          },
          review: {
            title: '2. Review',
            buttons: {
              back: 'Back',
              next: 'Send',
            },
            table: {
              line: 'Line',
            },
          },
          validation: {
            title: '3. Validation',
            buttons: {
              resendFile: 'Resend file',
              back: 'Back',
              next: 'Next',
            },
            table: {
              line: 'Line',
              status: 'Status',
            },
            modal: {
              title: 'Notice: Information about Rejected CPFs.',
              subtitle:
                "By continuing, only approved CPFs will be registered. Rejected CPFs will be recorded in the database with the status 'Rejected', but they can be re-evaluated if a new spreadsheet containing these CPFs is submitted.",
              buttons: {
                cancel: 'Cancel',
                next: 'Continue',
              },
            },
          },
          success: {
            title: '4. All set!',
            heading: 'Registration completed',
            dialog: 'successfully!',
            button: {
              collaborators: 'View list of collaborators',
            },
          },
        },
      },
      profile: {
        pageHeader: {
          previousStep: 'Collaborators',
        },
        personalInfo: {
          title: 'Personal information',
          fields: {
            name: 'Full name',
            preferredName: 'Social name',
            emancipatedMinor: 'Emancipated minor?',
            birthDate: 'Date of birth',
            document: 'CPF',
            maritalStatus: 'Marital status',
            motherName: 'Mother name',
          },
        },
        contact: {
          title: 'Contact',
          fields: {
            email: 'Email',
            phoneNumber: 'Phone',
          },
        },
        address: {
          title: 'Address',
          fields: {
            zipCode: 'Zip code',
            street: 'Street',
            country: 'Country',
            neighborhood: 'Neighborhood',
            number: 'Number',
            complement: 'Complement',
            city: 'City',
            state: 'State',
          },
        },
        maritalStatus: {
          [MaritalStatusEmployeeEnum.DIVORCED]: 'Divorced',
          [MaritalStatusEmployeeEnum.MARRIED]: 'Married',
          [MaritalStatusEmployeeEnum.SINGLE]: 'Single',
          [MaritalStatusEmployeeEnum.WIDOWED]: 'Widowed',
          [MaritalStatusEmployeeEnum.UNKNOWN]: '-',
        },
        errors: {
          zipCode: 'Invalid zip code',
        },
      },
    },
    listBatches: {
      title: 'Registrations',
      success: 'Got it',
      addCollaborators: 'Add collaborators',
      batchDetails: {
        batchNumber: 'Batch number',
        proposalsAccepted: 'CPF accepted',
        proposalsWithErrors: 'CPF refused',
        proposalsDeclined: 'CPF not processed',
        downloadRejectedCpfSheet: 'Download rejected CPF spreadsheet',
        batchDetails: 'Batch details',
        batchNotFound: 'Batch not found',
      },
      tabs: {
        all: 'All',
        processing: 'Processing',
        processed: 'Processed',
      },
    },
    recharge: {
      releaseOrderModal: {
        title: 'Do you want to confirm the distribution of the balance to the collaborators?',
        content: 'Once the value is distributed, this action cannot be undone.',
        confirm: 'Distribute',
        cancel: 'Cancel',
      },
      balanceRecharge: {
        title: 'Collaborators',
        subtitle: 'Balance recharge',
        tabs: {
          all: 'All',
          done: 'Distributed',
          canceled: 'Canceled',
          pending: 'Pending payment',
          ready: 'Ready to distribute',
        },
        table: {
          status: 'Status',
          registerDate: 'Request date',
          amount: 'Amount',
          actions: 'Actions',
        },
        status: {
          approved: 'Approved',
          ready: 'Ready to release',
          pending: 'Pending payment',
          canceled: 'Canceled',
          failed: 'Failed',
        },
        button: 'New recharge',
      },
      newRecharge: {
        pageHeader: {
          previousStep: 'Balance recharge',
          currentStep: 'New recharge',
          subtitle: 'New recharge',
        },
        stepByStep: {
          step0: 'Send',
          step1: 'Validation',
          step2: 'Payment method',
          step3: 'Distribution method',
          step4: 'Payment',
        },
        tabs: {
          send: {
            title: 'Send the spreadsheet',
            sheetModel: {
              title:
                'To proceed with the recharge, your spreadsheet must follow the provided template. You can download our template spreadsheet by clicking here *to download the template spreadsheet*.',
              button: 'Download template spreadsheet',
            },
            divider: 'or, if you already have the spreadsheet ready, upload it',
            fileArea: {
              title: 'Upload your spreadsheet',
              formats: 'Files need to be in .csv format',
              button: 'Select file',
              paragraph: 'or drag and drop here',
            },
            banners: {
              mayTakeAWhile:
                'Depending on the number of orders registered in the spreadsheet, the validation process may take a few minutes.',
              maxItems: 'Send up to 10000 collaborators per order.',
            },
            buttons: {
              cancel: 'Cancel',
              next: 'Next',
            },
            redirectDialog: {
              title: 'Successfully created order.',
              toList: 'Return to recharge list',
            },
          },
          review: {
            title: 'Spreadsheet validation',
            buttons: {
              cancel: 'Cancel',
              resend: 'Resend spreadsheet',
            },
            errors: {
              errorsFound: 'Errors found. Fix them and resend the spreadsheet.',
              line: 'Line',
              duplicated_cpf: 'Duplicated CPF',
              invalid_value: 'Invalid value',
              not_found_cpf: 'CPF not found',
            },
          },
          paymentType: {
            title: 'Payment method',
            summary: {
              title: 'Order summary',
            },
            options: {
              title: 'Select one or more payment methods',
              subtitle:
                'You can also pay using the Credits available in your wallet. The balance will be available to the collaborators after the payment is confirmed.',
              ted: {
                title: 'Pay with TED',
              },
              pix: {
                title: 'PIX',
                subtitle: 'Instant',
              },
              billet: {
                title: 'Bank slip',
                subtitle: 'Up to 3 business days',
              },
              credits: {
                title: 'Wallet Credits',
                subtitle: 'The payment confirmation should be confirmed within 2 hours *every day*.',
                currentBalance: 'Current balance:*{{balance}}*',
                hint: 'It is possible to use your Credits with TED',
              },
              total: 'Total amount to be paid',
            },
            buttons: {
              cancel: 'Cancel',
              next: 'Continue',
              validateOrder: 'Create order',
              goBack: 'Go back',
            },
            totalValue: {
              base: 'Total value to be paid',
              both: 'via TED + Wallet Credits',
              ted: 'via TED',
              credits: 'via Wallet Credits',
              discount: 'Credits Wallet discount',
              bothOptionsBalance: '{{value}} - {{discount}} (Credits Wallet discount)',
              finalBalance: 'Your final balance after payment will be: {{finalBalance}}',
              noBalance:
                'Your balance is not enough to pay the total amount. Please complete the payment with TED.',
            },
          },
          distributionType: {
            summary: {
              title: 'Order summary',
            },
            title: 'When will the balance be available to the collaborators?',
            options: {
              schedule: 'Schedule to a specific date',
              scheduleDate: 'Select the date: ',
              scheduleDateWarning: 'Pay attention to not schedule the payment on holidays',
              manual: 'Release manually after payment confirmation',
              instantWeekDay: 'Up to 1 hour, week day, commercial hours',
              instantWeekend: 'Up to 2 hours every day',
            },
            datepicker: {
              months: {
                january: 'January',
                february: 'February',
                march: 'March',
                april: 'April',
                may: 'May',
                june: 'June',
                july: 'July',
                august: 'August',
                september: 'September',
                october: 'October',
                november: 'November',
                december: 'December',
              },
              weekdays: {
                sunday: 'Sunday',
                monday: 'Monday',
                tuesday: 'Tuesday',
                wednesday: 'Wednesday',
                thursday: 'Thursday',
                friday: 'Friday',
                saturday: 'Saturday',
              },
            },
            buttons: {
              goBack: 'Go back',
              done: 'Confirm',
            },
            totalValue: {
              base: 'Total value to be paid',
              both: 'via TED + Wallet Credits',
              ted: 'via TED',
              credits: 'via Wallet Credits',
              bothOptionsFinal: 'Final value of: {{value}} - {{discount}} (Credits Wallet discount)',
              creditsOnlyFinal: 'Final value of: {{value}} (Credits Wallet discount)',
            },
          },
          payment: {
            title: 'Payment',
            header: {
              title: 'Pay {{currency}} via {{method}}',
              subtitle: 'and the balance will be available within an hour!',
            },
            items: {
              title: 'Account details',
              agency: 'Agency',
              account: 'Account',
              cnpj: 'CNPJ',
              bankNumber: 'Bank',
              legalName: 'Social',
            },
            goBack: 'Go back to recharge list',
          },
        },
      },
      rechargeDetails: {
        order: 'Order: ',
        registeredSpreadsheet: 'Registered spreadsheet',
        balancesSent: 'balances successfully sent',
        balancesFailed: 'balances failed to send',
        stillProcessing: 'Order is still processing',
        sendEmail: 'Send details by email',
        sendStatement: 'Send statement by email',
        releaseOrder: 'Release balances',
        payOrder: 'Pay order',
        cancelOrder: 'Cancel order',
        orderSummary: {
          title: 'Order Summary',
        },
        orderStatusCodes: {
          approved: 'Approved',
          partial: 'Partial approved',
          ready: 'Ready to release',
          pending: 'Pending payment',
          canceled: 'Canceled',
          failed: 'Failed release',
          processing: 'Processing order',
          processingSpreadsheet: 'Processing spreadsheet',
          spreadsheetFailure: 'Processing failure',
          unknown: 'Unknown error',
        },
        sendEmailDialog: {
          title: 'Send spreadsheet with order details to email',
          content:
            'This process may take some time, but do not worry. You will receive it soon. Otherwise, check your spam box.',
          confirm: 'Send',
          cancel: 'Cancel',
        },
        sendStatementDialog: {
          title: 'Send statement to email',
          content: 'This process may take some time, but do not worry. You will receive it soon.',
          confirm: 'Send',
          cancel: 'Cancel',
        },
        successDialog: {
          title: 'Email sent successfully',
        },
        canceledDialog: {
          title: 'Order canceled',
        },
      },
    },
    permissions: {
      pageHeader: {
        title: 'Permissions',
        button: 'Add permission',
      },
      fields: {
        name: 'FullName',
        document: 'CPF',
        email: 'Email',
        role: {
          label: 'Permission',
          options: {
            viewer: 'Viewer',
            admin: 'Admin',
            operator: 'Operator',
          },
        },
      },
      button: {
        cancel: 'Cancel',
        send: 'Send',
      },
    },
    home: {
      pageHeader: {
        currentStep: 'Home',
        subtitle: 'Welcome!',
        switch: 'Switch to',
      },
      credits: {
        title: 'Available balance',
        add: 'Add credit',
      },
      sheets: {
        title: 'Sheets',
        collaborators: 'Collaborators',
        orders: 'Orders',
      },
      rechargeHistory: {
        title: 'Recharge history',
        add: 'Add recharge',
      },
      close: 'Close',
    },
    cards: {
      cardsList: {
        pageHeader: {
          title: 'Request cards',
          button: 'Request cards',
        },
        tabs: {
          all: 'All',
          delivered: 'Delivered',
          pending: 'In process',
        },
      },
      newCards: {
        pageHeader: {
          title: 'Request cards',
        },
        tabs: {
          quantity: {
            title: 'Quantity',
            fields: {
              quantity: {
                section: 'How many cards do you want?',
                label: 'Number of cards',
                placeholder: 'Quantity',
                assistiveText: 'Minimum: 1 unit | Maximum: 5,000 units.',
              },
              date: {
                label: 'Expiration date: {{date}}',
              },
            },
            banner: 'The expiration date of the cards is {{year}} years from the date of issue.',
            button: {
              cancel: 'Cancel',
              next: 'Next',
            },
          },
          address: {
            title: 'Address',
            section: {
              title: 'Select the delivery address:',
              add: 'Add new address',
            },
            form: {
              title: 'New address',
              fields: {
                zipCode: 'ZIP code *',
                street: 'Street *',
                neighborhood: 'Neighborhood *',
                number: 'Number *',
                complement: 'Complement',
                city: 'City *',
                state: 'State *',
              },
              button: {
                add: 'Add new address',
                cancel: 'Cancel',
              },
            },
            button: {
              cancel: 'Cancel',
              next: 'Next',
            },
          },
          review: {
            title: 'Order review',
            row: {
              quantity: 'Number of cards requested',
              date: 'Card expiration date',
            },
            section: {
              title: 'Review your order information:',
              summary: 'Order summary:',
              address: 'Delivery address:',
            },
            button: {
              cancel: 'Cancel',
              confirm: 'Confirm and request',
            },
          },
        },
        button: {
          success: 'Got it',
          error: 'Try again',
        },
      },
    },
    settings: {
      enterpriseAddress: {
        pageHeader: {
          text: 'Companies',
          title: 'Settings',
        },
        section: {
          title: 'Company Addresses',
          form: {
            createTitle: 'New Address',
            editTitle: 'Edit Address',
            fields: {
              zipCode: 'ZIP Code *',
              street: 'Street *',
              neighborhood: 'Neighborhood *',
              number: 'Number *',
              complement: 'Complement',
              city: 'City *',
              state: 'State *',
              isMain: 'Is this the main address?',
            },
            button: {
              cancel: 'Cancel',
              edit: 'Update Address',
              add: 'Add New Address',
            },
          },
          add: 'Add New Address',
        },
        delete: {
          title: 'Are you sure you want to delete the address?',
          primary: 'Delete Address',
          secondary: 'Cancel',
        },
      },
    },
    addCredit: {
      title: 'Wallet Credit',
      moneyInput: {
        subtitle:
          'Wallet Credit is an amount available on the platform to be used for paying benefits orders at any time.',
        balanceRules: {
          title: 'Balance Usage Rules',
          description:
            'The available wallet balance can only be used for orders of the types Instantaneous or Scheduled. If the order is of the Manual type, payment must be made via a bank transfer (TED) for the exact amount of the open order, unless there is already an Instantaneous or Scheduled order scheduled for the same date.',
          listTitle: 'Orders follow the priority order below:',
          list: {
            instantaneous: 'Instantaneous',
            scheduled: 'Scheduled',
            manual: 'Manual',
          },
          conclusion: 'Thus, instantaneous and scheduled orders are processed first, before manual ones.',
        },
        fields: {
          amount: {
            label: 'Amount to Add',
            placeholder: '0.00',
            warning:
              '*Attention!* The balance will be credited within up to 1 hour, only on business days during business hours.',
          },
        },
        payment: {
          currentBalance: 'Current balance: {{currency}}',
          amountToAdd: 'Amount to pay: {{currency}}',
          totalBalance: 'Total balance after adding credit: {{currency}}',
        },
        buttons: {
          confirm: 'Confirm',
          back: 'Back',
        },
      },
      paymentDetails: {
        title: 'Payment',
        header: {
          title: 'Pay *R$ {{currency}}* via {{method}}',
          subtitle: 'and the balance will be available within an hour!',
        },
        items: {
          title: 'Account Details',
          agency: 'Agency',
          account: 'Account',
          cnpj: 'CNPJ',
          bankNumber: 'Bank',
          legalName: 'Legal Name',
        },
        goBack: 'Go to home',
      },
    },
  },
};
