import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  useBatchProposalServiceBatchProposalControllerCreateBatchProposal,
  useBatchProposalServiceBatchProposalControllerGetProposalBatches,
} from '~/api/queries';
import { BatchProposalJsonDto, ColumnDto, GetProposalBatches, OpenAPI } from '~/api/requests';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { setSuccess } from '~/redux/reducers/application';
import { selectToken } from '~/redux/reducers/user';
import { SuccessEnum } from '~/translates/success/types';

import { fields } from './fields';

export interface ListBatchesProps {
  tab: number;
  batches: GetProposalBatches[];
  isLoading: boolean;
  onReachEndLoading: boolean;
  handleBatchClick: (id: string) => void;
  onChangeTab: (tab: number) => void;
  showAddCollaborators: boolean;
  handleShowAddCollaborators: (value: boolean) => void;
  onSubmitCollaborators: (data: BatchProposalJsonDto[]) => Promise<void>;
  fields: ColumnDto[];
}

const BATCHES_PER_PAGE = 15;

export const useBatchesView = (): ListBatchesProps => {
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<GetProposalBatches[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [onReachEndLoading, setOnReachEndLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [tab, setTab] = useState(0);
  const [showAddCollaborators, setShowAddCollaborators] = useState(false);

  const batchProposal = useBatchProposalServiceBatchProposalControllerCreateBatchProposal({
    onSuccess: () => {
      dispatch(setSuccess(SuccessEnum.BATCH_PROPOSAL_UPLOADED));
      setShowAddCollaborators(false);

      setCurrentPage(1);
      setData([]);
      setEnabled(true);
      onBatchesRefetch();
    },
  });

  const getStatus = (tabIndex: number) => {
    const status: { [key: number]: number | undefined } = {
      1: 2, // processing
      2: 3, // processed
    };

    return status[tabIndex];
  };

  const {
    data: batches,
    isPending: isBatchesPending,
    isRefetching: isBatchesRefetching,
    isError: isBatchesError,
    refetch: onBatchesRefetch,
  } = useBatchProposalServiceBatchProposalControllerGetProposalBatches(
    {
      page: currentPage,
      perPage: BATCHES_PER_PAGE,
      statusId: getStatus(tab),
    },
    ['listBatches', currentPage, tab],
    { enabled }
  );

  useOnReachEnd(() => {
    if (!onReachEndLoading && !isBatchesPending && data.length > 0 && enabled) {
      setOnReachEndLoading(true);
      setCurrentPage((prev) => prev + 1);
    }
  });

  useEffect(() => {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      Authorization: `Bearer ${token}`,
    };
  }, [token]);

  useEffect(() => {
    if (batches) {
      const newBatches = batches?.data;
      currentPage === 1 ? setData(newBatches) : setData((prev) => [...prev, ...newBatches]);
    }

    setOnReachEndLoading(false);

    if (isBatchesError || batches?.data.length === 0) {
      setEnabled(false);
    }
  }, [batches, currentPage]);

  useEffect(() => {
    setOnReachEndLoading(isBatchesPending && currentPage > 1 && enabled);
  }, [isBatchesPending, currentPage]);

  const onChangeTab = (tab: number) => {
    setTab(tab);
    setCurrentPage(1);
    setData([]);
    setEnabled(true);
    onBatchesRefetch();
  };

  const handleBatchClick = (batchId: string) => {
    navigate(RH_SCREENS_DEFINITIONS.detailsBatch, { state: { batchId } });
    setData([]);
  };

  const handleShowAddCollaborators = (value: boolean) => {
    setShowAddCollaborators(value);
  };

  const onSubmitCollaborators = async (data: BatchProposalJsonDto[]) => {
    const updatedData = data.map((item) => {
      return {
        ...item,
        sexo: (item.sexo as string) ?? '',
        estado_civil: (item.estado_civil as string) ?? '',
        emancipado_menor: (item.emancipado_menor as string) ?? '',
        complemento_endereco: (item.complemento_endereco as string) ?? '',
        nome_preferido: (item.nome_preferido as string) ?? '',
      } as BatchProposalJsonDto;
    });

    await batchProposal.mutateAsync({
      requestBody: {
        json: updatedData,
      },
    });
  };

  return {
    tab,
    batches: data,
    isLoading: (isBatchesPending || isBatchesRefetching) && currentPage === 1 && enabled,
    onReachEndLoading,
    handleBatchClick,
    onChangeTab,
    showAddCollaborators,
    handleShowAddCollaborators,
    onSubmitCollaborators,
    fields: fields,
  };
};
